import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loginVisible: false,
  },
  getters: {},
  mutations: {
    updateLogin(state, val) {
      state.loginVisible = val;
    },
  },
  modules: {},
});
