<template>
  <div class="index-page">
    <!-- banner -->
    <div class="swipe-wrap">
      <van-swipe class="my-swipe" :autoplay="5000" :show-indicators="false">
        <van-swipe-item v-for="(image, index) in banners" :key="index"
          @click="toBanner(image.redirect, image.extendView)">
          <img class="banner-img" :src="image.img" />
        </van-swipe-item>
      </van-swipe>
    </div>
    <!-- 通知 -->
    <div class="notice-wrap">
      <van-notice-bar :scrollable="false">
        <template v-slot:left-icon>
          <img class="notice-icon" :src="require('@/assets/images/home/notice.png')" />
        </template>
        <van-swipe vertical class="notice-swipe" :autoplay="3000" :show-indicators="false">
          <van-swipe-item v-for="(item, index) in notice" :key="index">{{
            item
          }}</van-swipe-item>
        </van-swipe>
      </van-notice-bar>
    </div>
    <!-- 商品 -->
    <div class="prodect-wrap">
      <van-swipe class="prodect-swipe" indicator-color="#010001" @change="swiperChange"
        :initial-swipe="swiperIndex">
        <van-swipe-item v-for="i in Math.ceil(products.length / 12)" :key="i">
          <van-grid :border="false">
            <van-grid-item v-for="item in products.slice((i - 1) * 12, i * 12)" :key="item.id"
              @click="toProduct(item)">
              <img class="grid-img" :src="item.img" />
              <div class="grid-name">{{ item.name }}</div>
              <div class="grid-intro">{{ item.info }}</div>
            </van-grid-item>
          </van-grid>
        </van-swipe-item>
      </van-swipe>
    </div>
    <!-- 热门 -->
    <div class="hot-wrap">
      <div class="hot-title">
        <img class="hot-icon" :src="require('@/assets/images/home/hot.png')" />
        <div>热门权益</div>
      </div>
      <div class="hot-item" v-for="item in hotProductSku" :key="item.id" @click="toBuy(item)">
        <img class="item-img" :src="item.product?.img" />
        <div class="item-info">
          <div class="title-wrap">
            <div class="item-title">{{ item.name }}</div>
            <div class="num">{{ item.saleNum }}人购买</div>
          </div>
          <div class="item-price-wrap">
            <div class="item-vip-price">
              会员价：<span class="price">￥{{ item.vipPriceView }}</span>
            </div>
            <div class="tag">会员立省{{ item.savePriceView }}元</div>
          </div>
          <div class="item-price-wrap">
            <div class="item-price">
              非会员价：<span class="price">￥{{ item.priceView }}</span>
            </div>
            <div class="item-market-price">
              市场价：￥{{ item.marketPriceView }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="orderVisible">
        <Order :show="orderVisible" @closeOverlay="closeOverlay" :detail="order" />
      </div>
      <div v-if="packetVisible">
        <Packet @closeOverlay="closeOverlay" />
      </div>
    </div>
  </div>
</template>

<script>
import { Swipe, SwipeItem, NoticeBar, Grid, GridItem } from "vant";
import { listIndex } from "@/api/index";
import Order from "@/components/Order.vue";
import Packet from "./components/Packet.vue";
export default {
  name: 'home',
  data() {
    return {
      banners: [],
      notice: [],
      products: [],
      hotProductSku: [],
      orderVisible: false,
      order: {},
      packetVisible: false,
      swiperIndex: 0
    };
  },
  components: {
    VanSwipe: Swipe,
    VanSwipeItem: SwipeItem,
    VanNoticeBar: NoticeBar,
    VanGrid: Grid,
    VanGridItem: GridItem,
    Order: Order,
    Packet,
  },
  created() {
    // 充值会员后回调到首页  链接中带token
    const token = this.$route.query?.token;
    if (token) {
      localStorage.setItem("access_token", token);
      this.getList();
    } else {
      this.getList();
    }
  },
  methods: {
    async getList() {
      try {
        const data = await listIndex();
        const { banners, hotProductSku, notice, products, isRedPacket } = data;
        if (banners) {
          this.banners = banners;
        }
        if (notice) {
          this.notice = notice;
        }
        if (products) {
          this.products = products;
        }
        if (hotProductSku) {
          this.hotProductSku = hotProductSku;
        }
        if (isRedPacket) {
          this.packetVisible = true;
        }
      } catch (e) { }
    },
    swiperChange(index) {
      this.swiperIndex = index
    },
    toProduct({ id, categoryId }) {
      // if (categoryId === 1) {
      //   this.$router.push({ path: "/telCharge" });
      // } else {
      this.$router.push({ path: "/product", query: { id } });
      // }
    },
    closeOverlay() {
      this.orderVisible = false;
      this.packetVisible = false;
    },
    toBuy(item) {
      const access_token = localStorage.getItem("access_token");
      if (!!access_token) {
        this.order = item;
        this.orderVisible = true;
      } else {
        this.$store.commit("updateLogin", true);
      }
    },
    toBanner(redirect, { url }) {
      if (redirect === 1) {
        if (url.indexOf("http") === 0 || url.indexOf("www") === 0) {
          // 外部链接
          window.location.href = url
        } else {
          this.$router.push({ path: url });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.index-page {
  background-color: #f8f8f8;
  min-height: 100vh;
  padding-top: 1px;
  padding-bottom: 50px;
  padding-left: 12px;
  padding-right: 12px;
  box-sizing: border-box;
}
.swipe-wrap {
  border-radius: 8px 8px 8px 8px;
  margin: 6px auto;
  overflow: hidden;
}
.banner-img {
  width: 100%;
  height: 100%;
}

.van-notice-bar {
  height: 30px;
  background: linear-gradient(200deg, #e7f0ff 0%, #ffffff 100%);
  border-radius: 8px 8px 8px 8px;
  border: 1px solid #bbd6fe;
  color: #000000;
  padding: 0 11px;
  font-size: 12px;
}
.notice-swipe {
  height: 30px;
  line-height: 30px;
}
.notice-icon {
  width: 17px;
  height: 17px;
  margin-right: 2px;
}

.prodect-wrap {
  margin-top: 10px;
  margin-bottom: 10px;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  overflow: hidden;
}
.prodect-swipe {
  padding-bottom: 20px;
}
.grid-img {
  width: 34px;
  height: 34px;
  margin-bottom: 13px;
}
.grid-name {
  font-size: 13px;
  font-weight: 500;
  color: #000000;
  text-align: center;
  margin-bottom: 8px;
}
.grid-intro {
  font-size: 11px;
  font-weight: 400;
  color: #999999;
  text-align: center;
}
:deep(.van-grid-item__content) {
  padding: 10px 0;
}

.hot-wrap {
  .hot-title {
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 600;
    color: #000000;
    margin-bottom: 13px;
  }
  .hot-icon {
    width: 17px;
    height: 21px;
    margin-right: 8px;
  }
  .hot-item {
    margin-bottom: 10px;
    height: 88px;
    padding: 0 15px 0 13px;
    box-sizing: border-box;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    border: 1px solid #ccdeff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
  }
  .item-info {
    flex: 1;
    overflow: hidden;
  }
  .item-img {
    width: 47px;
    height: 47px;
    margin-right: 3px;
    flex-shrink: 0;
  }
  .item-price-wrap {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .title-wrap {
    margin-bottom: 7px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .item-title {
    flex: 1;
    flex-shrink: 0;
    font-size: 13px;
    font-weight: 500;
    color: #000000;
    line-height: 22px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .num {
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    flex-shrink: 0;
    white-space: nowrap;
  }
  .item-vip-price {
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    .price {
      color: #ff4654;
      font-weight: 600;
    }
  }
  .item-price {
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    line-height: 12px;
    .price {
      color: #666666;
      font-weight: 600;
    }
  }
  .tag {
    display: inline-block;
    margin-bottom: 5px;
    height: 16px;
    line-height: 16px;
    padding: 0 5px;
    background: linear-gradient(133deg, #ff781b 0%, #ffb800 100%);
    border-radius: 3px 1px 8px 2px;
    font-size: 10px;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
  }
  .item-market-price {
    text-decoration: line-through;
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    line-height: 12px;
  }
}
.prodect-wrap {
  :deep(.van-swipe__indicator) {
    background-color: #b5b5b5;
  }
}
</style>