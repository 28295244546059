<template>
  <div id="app">
    <keep-alive :include="['telCharge', 'home']">
      <router-view />
    </keep-alive>
    <van-tabbar v-show="isShow" v-model="active" active-color="#307DE6" inactive-color="#98A1AF">
      <van-tabbar-item to="/">
        <span>首页</span>
        <template #icon="props">
          <img :src="props.active ? icon.active : icon.inactive" />
        </template>
      </van-tabbar-item>
      <van-tabbar-item @click="toUser">
        <span>我的</span>
        <template #icon="props">
          <img :src="props.active ? icon.active2 : icon.inactive2" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
    <div v-if="$store.state.loginVisible">
      <Login @closeOverlay="closeOverlay" />
    </div>
  </div>
</template>

<script>
import { Tabbar, TabbarItem } from "vant";
import Login from "@/components/Login.vue";
export default {
  data() {
    return {
      active: 0,
      icon: {
        active: require("@/assets/images/icon1-s.png"),
        inactive: require("@/assets/images/icon1.png"),
        active2: require("@/assets/images/icon2-s.png"),
        inactive2: require("@/assets/images/icon2.png"),
      },
      isShow: true,
    };
  },
  components: {
    vanTabbar: Tabbar,
    vanTabbarItem: TabbarItem,
    Login: Login,
  },
  watch: {
    $route(to) {
      if (to.path === "/") {
        this.isShow = true;
        this.active = 0;
      } else if (to.path === "/user") {
        this.isShow = true;
        this.active = 1;
      } else {
        this.isShow = false;
      }
    },
  },
  created() {
    const path = this.$route.path;
    if (path === "/") {
      this.isShow = true;
      this.active = 0;
    } else if (path === "/user") {
      this.isShow = true;
      this.active = 1;
    } else {
      this.isShow = false;
    }
  },
  methods: {
    toUser() {
      const access_token = localStorage.getItem("access_token");
      if (!!access_token) {
        this.$router.push({ path: "/user" });
      } else {
        this.$store.commit("updateLogin", true);
        this.active = 0;
      }
    },
    closeOverlay() {
      this.$store.commit("updateLogin", false);
    },
  },
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
dl,
dd {
  margin: 0;
}

ul,
ol {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

img {
  border: none;
}
.van-tabbar--fixed {
  width: 375px;
}
</style>
