import { aliWapPay, aliWapPayVip } from "@/api";
export const payAli = async (path, orderNu) => {
  try {
    const data = await axios(path)({ orderNu: orderNu });
    if (data.status) {
      this.$toast(data.message);
      throw new Error(data.message);
    }
    return data;
  } catch (e) {
    this.$toast(e);
    throw new Error(e);
  }
};
const axios = (path) => {
  if (path === "vip") {
    return aliWapPayVip;
  } else {
    return aliWapPay;
  }
};
