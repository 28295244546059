<template>
  <div class="empty-com">
    <img class="icon" :src="require('@/assets/images/empty.png')" alt="">
    <div class="tip">～～～暂无数据～～</div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {},
  data() {
    return {
    }
  },
  computed: {
  },
  created() {
  },
  methods: {
  },
}
</script>
<style lang="scss" scoped>
.icon {
  display: block;
  width: 130px;
  height: 130px;
  margin: auto;
}
.tip {
  font-size: 12px;
  font-weight: 400;
  color: #999999;
  text-align: center;
}
</style>
