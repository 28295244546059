<template>
  <van-overlay :show="true">
    <div class="dialog-com">
      <div class="dialog-content" @click.stop>
        <div class="title">关注公众号</div>
        <div>
          <div class="text">1、微信扫描二维码；</div>
          <div class="text">2、微信搜索公众号“三众创联”</div>
        </div>
        <img class="code" :src="require('@/assets/images/user/code.jpg')" alt="">
        <van-button class="btn" type="info" :round="true" block
          @click="copyText">复制公众号名称</van-button>
        <div class="close-btn" @click="closeOverlay">关闭</div>
      </div>
    </div>
  </van-overlay>
</template>
<script>
import { Overlay, Button } from 'vant';
import { copyToClipboard } from "@/utils/copy.js";
export default {
  components: {
    VanOverlay: Overlay,
    VanButton: Button,
  },
  props: {},
  data() {
    return {
    }
  },
  computed: {
  },
  created() {
  },
  methods: {
    closeOverlay() {
      this.$emit('closeOverlay')
    },
    copyText() {
      copyToClipboard('三众创联')
        .then(() => {
          this.$toast('复制成功');
        })
        .catch(err => {
          this.$toast('复制失败');
        });
    },
  },
}
</script>
<style lang="scss" scoped>
.dialog-com {
  margin: 100px auto;
  width: 310px;
}
.dialog-content {
  width: 310px;
  background: linear-gradient(180deg, #b1d0ff 0%, #ffffff 15%);
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  border: 2px solid #ffffff;
  padding: 30px 16px 12px;
  box-sizing: border-box;
}
.title {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #000000;
  line-height: 22px;
  margin-bottom: 20px;
}
.van-overlay {
  z-index: 2;
}
.btn {
  margin-top: 20px;
  background-color: #337bff;
}
.close-btn {
  font-size: 13px;
  font-weight: 400;
  color: #999999;
  text-align: center;
  width: 50px;
  margin: 10px auto 0;
  line-height: 28px;
}
.text {
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  margin-left: 28px;
  line-height: 24px;
}
.code {
  margin: 10px auto 0;
  width: 190px;
  height: 190px;
  display: block;
}
</style>
