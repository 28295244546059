<template>
  <div class="product-page">
    <Nav :title="product.name"></Nav>
    <div v-if="list && list.length > 0">
      <div class="hot-item" v-for="item in list" :key="item.id" @click="toBuy(item)">
        <div class="item-title">{{ item.name }}</div>
        <div class="item-vip-price">
          会员价：<span class="price">￥{{ item.vipPriceView }}</span>
        </div>
        <div class="bottom">
          <div class="item-price">
            非会员价：<span class="price">￥{{ item.priceView }}</span>
          </div>
          <div class="item-market-price">
            市场价：￥{{ item.marketPriceView }}
          </div>
        </div>
      </div>
      <div class="intro-wrap">
        <div class="title">
          <img class="intro-icon" :src="require('@/assets/images/product/intro.png')" alt="" />
          使用说明
        </div>
        <div class="intro" v-html="product.introduction"></div>
      </div>
      <div v-if="orderVisible">
        <Order :show="orderVisible" @closeOverlay="closeOverlay" :detail="order" />
      </div>
    </div>
    <div v-else class="empty">
      <Empty />
    </div>
  </div>
</template>
<script>
import { quanYiInfo } from "@/api";
import Order from "@/components/Order.vue";
import Empty from "@/components/Empty.vue";
import Nav from "@/components/Nav.vue";
export default {
  name: 'product',
  components: {
    Order: Order,
    Empty,
    Nav
  },
  props: {},
  data() {
    return {
      list: [],
      product: {},
      orderVisible: false,
      order: {},
    };
  },
  computed: {},
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      const id = this.$route.query.id;
      try {
        const data = await quanYiInfo({ id: Number(id) });
        const { productSkus, product } = data;
        if (productSkus) {
          this.list = productSkus;
        }
        if (product) {
          this.product = product;
          document.title = product.name;
        }
      } catch (e) { }
    },
    toBuy(item) {
      const access_token = localStorage.getItem("access_token");
      if (!!access_token) {
        this.orderVisible = true;
        this.order = item;
      } else {
        this.$store.commit("updateLogin", true);
      }
    },
    closeOverlay() {
      this.orderVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.product-page {
  min-height: 100vh;
  background-color: #f8f8f8;
  padding-top: 10px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 20px;
  box-sizing: border-box;
}
.hot-item {
  margin-bottom: 10px;
  padding: 16px 15px 16px 13px;
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  border: 1px solid #ccdeff;
  overflow: hidden;
}

.item-title {
  margin-bottom: 7px;
  font-size: 13px;
  font-weight: 500;
  color: #000000;
  line-height: 22px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.item-vip-price {
  margin-bottom: 7px;
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  line-height: 12px;
  .price {
    color: #ff4654;
    font-weight: 600;
  }
}
.item-price {
  font-size: 12px;
  font-weight: 400;
  color: #999999;
  line-height: 12px;
  .price {
    color: #666666;
    font-weight: 600;
  }
}

.bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.item-market-price {
  text-decoration: line-through;
  font-size: 12px;
  font-weight: 400;
  color: #999999;
  line-height: 12px;
}
.intro-wrap {
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  padding: 16px 12px;
  .title {
    font-size: 14px;
    font-weight: 600;
    color: #000000;
    display: flex;
    align-items: center;
  }
  .intro {
    background: #f6f6f6;
    border-radius: 8px 8px 8px 8px;
    margin: 10px 0 0;
    padding: 16px 12px;
  }
  .intro-icon {
    width: 20px;
    height: 20px;
    margin-right: 2px;
  }
}
.empty {
  margin-top: 100px;
}
</style>
