<template>
  <van-overlay :show="true" @click="closeOverlay">
    <div class="dialog-com">
      <div class="dialog-content">
        <div class="unOpen-content" v-if="open === 0" @click.stop="openPacket">
          <div class="price"><span>￥</span>88.88</div>
          <div class="text">现金红包</div>
        </div>
        <div v-else class="open-content"></div>
      </div>
    </div>
  </van-overlay>
</template>
<script>
import { Overlay } from "vant";
export default {
  components: {
    VanOverlay: Overlay,
  },
  props: {},
  data() {
    return {
      open: 0,
    };
  },
  computed: {},
  created() { },
  methods: {
    closeOverlay() {
      this.$emit("closeOverlay");
    },
    openPacket() {
      this.open = 1;
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog-com {
  margin: 100px auto;
}

.unOpen-content {
  background-image: url('@/assets/images/home/icon1.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: 399px;
}
.open-content {
  background-image: url('@/assets/images/home/icon2.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  height: 399px;
}
.van-overlay {
  z-index: 2;
}
.price {
  color: #ff452e;
  font-size: 32px;
  font-weight: bold;
  line-height: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding-top: 160px;
  span {
    font-size: 20px;
    position: relative;
    bottom: 3px;
  }
}
.text {
  margin-top: 2px;
  font-size: 14px;
  color: #ff452e;
  text-align: center;
}
</style>
