<template>
  <div class="userPage">
    <div :class="[userinfo.isVip === 1 ? 'vip-info-wrap' : 'info-wrap']">
      <div class="nickname-wrap">
        <img class="avatar" :src="userinfo.picurl" alt="" />
        <div class="nickname">{{ userinfo.nickname }}</div>
      </div>
      <div class="user-level">
        <div class="tip" @click="toLanding">
          <img class="icon" :src="require('@/assets/images/user/vip.png')" alt="" />
          {{
            userinfo.isVip === 1
              ? `会员-有效期至：${userinfo.vipTimeView}`
              : "非会员-上万福利，等你来购>"
          }}
        </div>
        <div class="price-wrap">
          <div class="label">
            <img class="icon" :src="require('@/assets/images/user/coin.png')" alt="" />
            <div>累计节省金额</div>
          </div>
          <div class="price">￥{{ userinfo.saveMoneyView }}</div>
        </div>
      </div>
    </div>
    <div class="list">
      <van-cell-group inset>
        <van-cell title="兑换记录" is-link to="/record">
          <template #icon>
            <img class="icon" :src="require('@/assets/images/user/icon1.png')" alt="" />
          </template>
        </van-cell>
        <van-cell title="在线客服" is-link url="https://work.weixin.qq.com/kfid/kfc7b9c8ae2bfba2e3a">
          <template #icon>
            <img class="icon" :src="require('@/assets/images/user/icon2.png')" alt="" />
          </template>
        </van-cell>
        <van-cell title="关注公众号" is-link @click="toWechat">
          <template #icon>
            <img class="icon" :src="require('@/assets/images/user/icon3.png')" alt="" />
          </template>
        </van-cell>
        <van-cell title="关于我们" is-link to="/aboutUs">
          <template #icon>
            <img class="icon" :src="require('@/assets/images/user/icon4.png')" alt="" />
          </template>
        </van-cell>
      </van-cell-group>
      <van-cell-group inset class="setting">
        <van-cell title="设置" is-link to="/setting">
          <template #icon>
            <img class="icon" :src="require('@/assets/images/user/icon5.png')" alt="" />
          </template>
        </van-cell>
      </van-cell-group>
    </div>
    <div v-if="wechatVisible">
      <Wechat @closeOverlay="closeOverlay" />
    </div>
  </div>
</template>
<script>
import { Cell, CellGroup } from "vant";
import { myUserinfo } from "@/api";
import Wechat from "./components/Wechat.vue";
export default {
  components: {
    VanCell: Cell,
    VanCellGroup: CellGroup,
    Wechat,
  },
  props: {},
  data() {
    return {
      userinfo: {},
      wechatVisible: false,
    };
  },
  computed: {},
  created() {
    this.getUser();
  },
  methods: {
    toLanding() {
      if (this.userinfo.isVip === 1) return;
      this.$router.push("/landing");
    },
    async getUser() {
      try {
        const data = await myUserinfo();
        if (data.userinfo) this.userinfo = { ...data.userinfo };
      } catch (e) {
        console.log(e);
      }
    },
    toWechat() {
      this.wechatVisible = true;
    },
    closeOverlay() {
      this.wechatVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.userPage {
  background-color: #f8f8f8;
  min-height: 100vh;
  padding-top: 1px;
  padding-bottom: 50px;
  box-sizing: border-box;
}
.info-wrap {
  background-image: url('@/assets/images/user/bg.png');
  width: 100%;
  height: 120px;
  background-size: 100%;
  background-repeat: no-repeat;
  position: relative;
  padding-top: 40px;
}
.vip-info-wrap {
  background-image: url('@/assets/images/user/vip-bg.png');
  width: 100%;
  height: 111px;
  background-size: 100%;
  background-repeat: no-repeat;
  position: relative;
  padding-top: 40px;
}
.nickname-wrap {
  display: flex;
  align-items: center;
}
.nickname {
  font-size: 18px;
  font-weight: 600;
  color: #000000;
}
.avatar {
  margin-left: 20px;
  margin-right: 10px;
  width: 64px;
  height: 64px;
}
.user-level {
  width: 351px;
  margin: 0 12px;
  height: 78px;
  background: linear-gradient(244deg, #383227 0%, #222035 100%);
  border-radius: 8px 8px 8px 8px;
  position: absolute;
  bottom: -45px;
  .price-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
    .icon {
      width: 14px;
      height: 15px;
      margin-right: 6px;
    }
    .label {
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      display: flex;
      align-items: center;
    }
    .price {
      font-size: 18px;
      font-weight: 600;
      color: #f9c83b;
    }
  }
  .tip {
    width: 305px;
    height: 27px;
    background: #ffffff;
    border-radius: 10px 10px 0px 0px;
    display: flex;
    align-items: center;
    padding-left: 13px;
    box-sizing: border-box;
    font-size: 13px;
    font-weight: 400;
    color: #835821;
    position: relative;
    top: -12px;
    left: 0;
    right: 0;
    margin: auto;
    .icon {
      width: 20px;
      height: 20px;
      margin-right: 2px;
    }
  }
}
.list {
  margin: 70px 0 0;
  .van-cell-group--inset {
    margin-left: 12px;
    margin-right: 12px;
  }
  .setting {
    margin-top: 12px;
  }
  .icon {
    width: 24px;
    height: 24px;
    margin-right: 4px;
  }
  .van-cell {
    padding-top: 18px;
    padding-bottom: 18px;
  }
}
</style>
