<template>
  <van-overlay :show="true">
    <div class="login-com">
      <div class="login-content" @click.stop>
        <div class="title">登录</div>
        <div class="ipt-wrap">
          <div class="label">
            <img
              class="icon"
              :src="require('@/assets/images/login/phone.png')"
              alt=""
            />
            <div>请输入手机号码</div>
          </div>
          <van-field v-model="tel" type="tel" placeholder="11位手机号码" />
        </div>
        <div class="ipt-wrap">
          <div class="label">
            <img
              class="icon"
              :src="require('@/assets/images/login/code.png')"
              alt=""
            />
            <div>请输入短信验证码</div>
          </div>
          <van-field
            v-model="code"
            type="digit"
            placeholder="短信验证码"
            maxlength="6"
          >
            <template #button>
              <div
                :class="[time > 0 ? 'disable-send' : 'send-btn']"
                @click="sendSms"
              >
                {{ time > 0 ? `(${time})重新获取` : "发送验证码" }}
              </div>
            </template>
          </van-field>
        </div>
        <van-button
          class="login-btn"
          type="info"
          :disabled="btnDisable"
          :round="true"
          block
          @click="login"
          >立即登录</van-button
        >
        <div class="tip-wrap">
          <van-checkbox v-model="checked">
            <div class="tip">
              <span>我已阅读并同意</span>
              <router-link class="blue" to="/registerText"
                >《用户注册协议》</router-link
              >
              <router-link class="blue" to="/privacyText"
                >《隐私协议》</router-link
              >
            </div>
          </van-checkbox>
        </div>
        <div class="close-btn" @click="closeOverlay">关闭</div>
      </div>
    </div>
  </van-overlay>
</template>
<script>
import { Overlay, Field, Button, Checkbox } from "vant";
import { sendSms, sysLogin } from "@/api";
export default {
  components: {
    VanOverlay: Overlay,
    VanField: Field,
    VanButton: Button,
    VanCheckbox: Checkbox,
  },
  props: {},
  data() {
    return {
      tel: "",
      code: "",
      checked: false,
      time: 0,
    };
  },
  computed: {
    btnDisable: function () {
      const reg = /^1[0-9]{10}$/;
      return !reg.test(this.tel) || !this.code;
    },
  },
  created() {},
  methods: {
    closeOverlay() {
      this.$emit("closeOverlay");
    },
    async sendSms() {
      if (this.time > 0 || this.loading) return;
      this.loading = true;
      try {
        const data = await sendSms({ mobile: this.tel });
        this.loading = false;
        this.$toast("验证码已发送");
        this.time = 60;
        this.timer = setInterval(() => {
          this.time = this.time - 1;
          if (this.time === 0) {
            clearInterval(this.timer);
          }
        }, 1000);
      } catch (e) {
        this.loading = false;
      }
    },
    async login() {
      if (!this.checked) {
        this.$toast("请阅读并同意《用户注册协议》《隐私协议》");
        return;
      }
      if (this.loading) return;
      this.loading = true;
      try {
        const data = await sysLogin({ mobile: this.tel, code: this.code });
        this.loading = false;
        localStorage.setItem("access_token", data.token);
        this.closeOverlay();
      } catch (e) {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.login-com {
  // height: 100%;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  margin: 100px auto;
  width: 310px;
}
.login-content {
  width: 310px;
  background: linear-gradient(180deg, #b1d0ff 0%, #ffffff 15%);
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  border: 2px solid #ffffff;
  padding: 30px 16px 12px;
  box-sizing: border-box;
}
.title {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #000000;
  line-height: 22px;
  margin-bottom: 40px;
}
.van-overlay {
  z-index: 2;
}
.ipt-wrap {
  margin-bottom: 11px;
}
.label {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 400;
  color: #000000;
  margin-bottom: 8px;
  .icon {
    width: 18px;
    height: 18px;
    margin-right: 2px;
  }
}
.van-cell {
  background-color: #f6f6f6;
  border-radius: 8px 8px 8px 8px;
}
.send-btn {
  font-size: 15px;
  font-weight: 400;
  color: #1f7cff;
  line-height: 18px;
  text-decoration: underline;
}
.disable-send {
  color: #666;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
}
.login-btn {
  margin-top: 50px;
  background-color: #337bff;
}
.tip-wrap {
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-top: 7px;
  color: #888888;
  .tip {
    white-space: nowrap;
  }
  .blue {
    color: #337bff;
  }
  span {
    white-space: nowrap;
  }
}
:deep(.van-checkbox__icon) {
  font-size: 18px;
}
:deep(.van-checkbox__icon--checked .van-icon) {
  background-color: #fff;
  color: #337bff;
}
.close-btn {
  font-size: 13px;
  font-weight: 400;
  color: #999999;
  line-height: 18px;
  text-align: center;
  width: 50px;
  margin: 20px auto 10px;
}
</style>
