import axios from "axios";
import { Toast } from "vant";
import store from "@/store/index.js";
// create an axios instance
let withoutMsg = false; //取消报错提醒
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL, // url = base url + request url
  withCredentials: false, // send cookies when cross-domain requests
  timeout: 5000, // request timeout
  headers: {
    "content-type": "application/x-www-form-urlencoded",
  },
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    const access_token = localStorage.getItem("access_token");
    config.headers = {
      Authorization: "Bearer " + access_token,
      ...config.headers,
    };
    withoutMsg = config.withoutMsg;
    return config;
  },
  (error) => {
    // do something with request error
    console.log(error); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (!res) {
      Toast({
        message: "http错误",
        forbidClick: true,
      });
      return Promise.reject("http错误");
    }
    // if the custom code is not 20000, it is judged as an error.
    if (withoutMsg) {
      return res;
    } else if (res.status === 50501) {
      console.log(res);
      store.commit("updateLogin", true);
      Toast({
        message: res.message || "Error",
        forbidClick: true,
      });
      localStorage.removeItem("access_token");
      // window.location = "#/";
      return Promise.reject(res);
    } else if (res.status !== 10000) {
      console.log(res);
      Toast({
        message: res.message || "Error",
        forbidClick: true,
      });
      return Promise.reject(res);
    } else {
      return res.data;
    }
  },
  (error) => {
    console.log("err：" + error); // for debug
    Toast({
      message: error.message,
      forbidClick: true,
    });
    return Promise.reject(error);
  }
);

// JSON axios

export default service;
