import request from "@/utils/request";
import qs from "qs";
// 推广页
export function promotion(data) {
  return request({
    url: "/miniAuth/promotion",
    method: "post",
    data: qs.stringify(data),
  });
}
export function vipOrder(data) {
  return request({
    url: "/miniAuth/vipOrder",
    method: "post",
    data: qs.stringify(data),
  });
}
export function aliWapPayVip(data) {
  return request({
    url: "/miniAuth/aliWapPayVip",
    method: "post",
    data: qs.stringify(data),
    withoutMsg: true,
  });
}
// 拿到折扣重新提交订单
export function vipOrderRedPacket(data) {
  return request({
    url: "/miniAuth/vipOrderRedPacket",
    method: "post",
    data: qs.stringify(data),
    withoutMsg: true,
  });
}

// 首页
export function listIndex(data) {
  return request({
    url: "/miniAuth/index",
    method: "post",
    data: qs.stringify(data),
  });
}
// 发送验证码
export function sendSms(data) {
  return request({
    url: "/miniAuth/sendSms",
    method: "post",
    data: qs.stringify(data),
  });
}

// 话费列表
export function huaFeiInfo(data) {
  return request({
    url: "/miniAuth/huaFeiInfo",
    method: "post",
    data: qs.stringify(data),
  });
}

// 商品二级列表
export function quanYiInfo(data) {
  return request({
    url: "/miniAuth/quanYiInfo",
    method: "post",
    data: qs.stringify(data),
  });
}

// 登录
export function sysLogin(data) {
  return request({
    url: "/miniAuth/newLogin",
    method: "post",
    data: qs.stringify(data),
  });
}

// 用户信息
export function myUserinfo(data) {
  return request({
    url: "/mini/myUserinfo",
    method: "post",
    data: qs.stringify(data),
  });
}

// 提交订单
export function productOrder(data) {
  return request({
    url: "/mini/productOrder",
    method: "post",
    data: qs.stringify(data),
  });
}

// 支付宝支付
export function aliWapPay(data) {
  return request({
    url: "/mini/aliWapPay",
    method: "post",
    data: qs.stringify(data),
    withoutMsg: true,
  });
}

// 记录
export function productOrderList(data) {
  return request({
    url: "/mini/productOrderList",
    method: "post",
    data: qs.stringify(data),
  });
}

export function productOrderDetail(data) {
  return request({
    url: "/mini/productOrderDetail",
    method: "post",
    data: qs.stringify(data),
  });
}
