<template>
  <van-overlay :show="true">
    <div class="order-com">
      <div class="order-content" @click.stop>
        <div class="quanyi">购买权益</div>
        <div class="content">
          <div class="title">{{ detail.name }}</div>
          <div class="ipt-wrap">
            <div class="label">
              <div>请输入手机号码</div>
            </div>
            <van-field v-model="tel" type="tel" placeholder="11位手机号码" />
          </div>
          <van-button class="pay-btn" :disabled="payDisable" type="info" :round="true" block
            @click="toOrder">{{ detail.currentPriceView }}元 立即支付</van-button>
        </div>
        <div class="close-btn" @click="closeOverlay">关闭</div>
      </div>
    </div>
  </van-overlay>
</template>
<script>
import { Overlay, Field, Button } from "vant";
import { productOrder } from "@/api";
import { payAli } from "@/utils/pay";
export default {
  components: {
    VanOverlay: Overlay,
    VanField: Field,
    VanButton: Button,
  },
  props: {
    detail: Object,
  },
  data() {
    return {
      tel: "",
      loading: false,
    };
  },
  computed: {
    payDisable: function () {
      const reg = /^1[0-9]{10}$/;
      return !reg.test(this.tel);
    },
  },
  created() { },
  methods: {
    closeOverlay() {
      this.$emit("closeOverlay");
    },
    async toOrder() {
      if (this.loading) return;
      this.loading = true;
      try {
        const { orderNu } = await productOrder({
          categoryId: this.detail.categoryId,
          productId: this.detail.productId,
          productSkuId: this.detail.id,
          account: this.tel,
        });
        const res = await payAli("order", orderNu);
        // 创建一个新的div元素
        var newDiv = document.createElement("div");
        // 设置新div元素的HTML内容
        newDiv.innerHTML = res;
        // 获取<body>元素
        var body = document.body;
        // 将新div元素插入到<body>的末尾
        body.appendChild(newDiv);
        this.$nextTick(() => {
          document.forms[0].submit();
          body.removeChild(newDiv);
          this.loading = false;
          this.closeOverlay();
        });
        // this.$router.push({
        //   path: "/pay",
        //   query: { orderNu, price: this.detail.currentPriceView },
        // });
      } catch (e) {
        console.log(e);
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.van-overlay {
  z-index: 2;
}
.order-com {
  margin: 100px auto;
  width: 310px;
}
.order-content {
  background-image: url('@/assets/images/product/bg.png');
  background-repeat: no-repeat;
  background-size: 100%;
  background-color: #ffffff;
  border-radius: 16px 16px 16px 16px;
  border: 2px solid #ffffff;
  overflow: hidden;
  padding-top: 47px;
  padding-bottom: 12px;
  box-sizing: border-box;
  .quanyi {
    font-size: 32px;
    font-weight: bold;
    color: #337bff;
    line-height: 22px;
    text-align: center;
    margin-bottom: 30px;
  }
  .content {
    background: #ffffff;
    border-radius: 30px 30px 0px 0px;
    padding: 30px 24px 0;
  }
  .title {
    width: 255px;
    font-size: 15px;
    font-weight: 600;
    color: #000000;
    line-height: 24px;
    text-align: center;
    margin-bottom: 24px;
  }
  .ipt-wrap {
    margin-bottom: 11px;
  }
  .label {
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 400;
    color: #000000;
    margin-bottom: 8px;
  }
  .van-cell {
    background-color: #f6f6f6;
    border-radius: 8px 8px 8px 8px;
  }
  .pay-btn {
    margin-top: 24px;
    background-color: #337bff;
  }
}
.close-btn {
  font-size: 13px;
  font-weight: 400;
  color: #999999;
  line-height: 18px;
  text-align: center;
  width: 50px;
  margin: 20px auto 10px;
}
</style>
