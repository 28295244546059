<template>
  <van-nav-bar v-if="show" class="app-nav" :title="title||pageTitle" left-arrow
    @click-left="onClickLeft" fixed placeholder />
</template>
<script>
import { NavBar } from 'vant';
export default {
  name: 'navCom',
  components: {
    VanNavBar: NavBar
  },
  props: {
    title: String
  },
  data() {
    return {
      pageTitle: '',
      show: false
    }
  },
  created() {
    // if (!window.plus) {
    //   // 运行在移动 App 中
    //   this.show = true
    // } else {
    //   this.show = false
    // }
  },
  mounted() {
    const pageTitle = this.$route.meta.title;
    this.pageTitle = pageTitle
  },
  methods: {
    onClickLeft() {
      this.$router.back();
    }
  },
}
</script>
<style lang="scss" >
.app-nav .van-nav-bar .van-icon {
  color: #4c4c4c;
}
.app-nav .van-nav-bar__title {
  color: #000000;
}
</style>
