import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/Home/index.vue";
import Product from "../views/Product/index.vue";
import User from "../views/User/index.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: "联心选",
    },
  },
  {
    path: "/product",
    name: "product",
    component: Product,
    meta: {
      title: "联心选",
    },
  },
  {
    path: "/telCharge",
    name: "telCharge",
    component: () => import("../views/TelCharge/index.vue"),
    meta: {
      title: "话费充值",
    },
  },
  {
    path: "/user",
    name: "user",
    component: User,
    meta: {
      title: "我的",
    },
  },
  {
    path: "/pay",
    name: "pay",
    component: () => import("../views/Pay/index.vue"),
    meta: {
      title: "支付",
    },
  },
  {
    path: "/result",
    name: "result",
    component: () => import("../views/Result/index.vue"),
    meta: {
      title: "支付",
    },
  },
  {
    path: "/record",
    name: "record",
    component: () => import("../views/Record/index.vue"),
    meta: {
      title: "兑换记录",
    },
  },
  {
    path: "/AboutUs",
    name: "aboutUs",
    component: () => import("../views/AboutUs/index.vue"),
    meta: {
      title: "关于我们",
    },
  },
  {
    path: "/setting",
    name: "setting",
    component: () => import("../views/Setting/index.vue"),
    meta: {
      title: "设置",
    },
  },
  {
    path: "/registerText",
    name: "registerText",
    component: () => import("../views/RegisterText/index.vue"),
    meta: {
      title: "用户注册协议",
    },
  },
  {
    path: "/privacyText",
    name: "privacyText",
    component: () => import("../views/PrivacyText/index.vue"),
    meta: {
      title: "联心选《隐私政策》",
    },
  },
  {
    path: "/landing",
    name: "landing",
    component: () => import("../views/Landing/index.vue"),
    meta: {
      title: "联心选",
    },
  },
  {
    path: "/promotion",
    name: "promotion",
    component: () => import("../views/Promotion/index.vue"),
    meta: {
      title: "联心选",
    },
  },
  {
    path: "/extension",
    name: "extension",
    component: () => import("../views/Extension/index.vue"),
    meta: {
      title: "联心选",
    },
  },
  {
    path: "/Channel",
    name: "channel",
    component: () => import("../views/Channel/index.vue"),
    meta: {
      title: "联心选",
    },
  },
];

const router = new VueRouter({
  routes,
});

// router设置页面标题
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next(); //切记操作完一定要记得放行,否则无法正常跳转页面
});
export default router;
